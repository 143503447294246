import { render, staticRenderFns } from "./recruitdetails.vue?vue&type=template&id=6c9c92f7&scoped=true"
import script from "./recruitdetails.vue?vue&type=script&lang=js"
export * from "./recruitdetails.vue?vue&type=script&lang=js"
import style0 from "./recruitdetails.vue?vue&type=style&index=0&id=6c9c92f7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c9c92f7",
  null
  
)

export default component.exports
<template>
  <div class="information">
    <div class="bg">
      <img
        src="@imgs/zpxxbg.png"
        width="261px"
        height="91px"
        alt=""
        class="cpzx"
      />
    </div>
    <div class="infocontent">
      <div class="infobox">
        <div class="tohome">当前位置：<span  style="cursor:pointer" @click="toHome">首页</span>＞<span>招聘信息</span></div>
        <div class="title">
          <div class="d1">Java中级开发工程师</div>
          <div class="d2">时间：2022-12-01 访问量：72</div>
        </div>
        <div class="details">
          <p class="p1">招聘单位:陕西玉龙医药有限公司</p>
          <p class="p1">联系电话:029-89302235</p>
          <p>任职资格:</p>
          <p>
            大学本科以上学历，计算机或相关专业；2年以上java实际项目开发经验；1年以上电商项目开发经验
          </p>
          <p>1.掌握Java并发编程</p>
          <p>2.熟练使用Spring Boot进行开发</p>
          <p>3.熟练使用Redis进行开发</p>
          <p>4.熟悉Netty</p>
          <p>5.熟练使用一种消息队列（如rocketmq、kafka等）</p>
          <p>6.熟悉关系型数据库MySQL，以及相应数据库调优、SQL优化</p>
          <p>7.熟悉主流Linux发行版</p>
          <p>8.熟悉ZooKeeper</p>
          <p>9.熟悉Nginx等</p>
          <p>10.热爱技术，对技术有不懈的追求，喜欢研究开源代码</p>
          <p>
            11.良好的学习能力、团队协作能力和沟通能力；善于思考，能独立分析和解决问题
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [
        { id: 0 },
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
      ],
    };
  },
  methods:{
      toHome(){
          this.$router.push('/')
      }
  }
};
</script>

<style lang="scss" scoped>
.information {

  .infocontent {
    width: 100%;
    height: 800px;
    background: #f1f1f1;
    padding-top: 13px;
    text-align: left;
    .infobox {
      width: 1193px;
      margin: 0 auto;
      color: #666666;
      .tohome {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        text-align: right;
      }
      .title {
        text-align: center;
        .d1 {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333232;
        }
        .d2 {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #666666;
        }
      }
      .details {
        width: 100%;
        border-top: 1px solid #e6e6e6;
        padding-top: 15px;
        margin-top: 10px;
        p {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666566;
          line-height: 18px;
        }
        .p1 {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4d4c4c;
        }
      }
    }
  }
}
</style>